import * as SheetPrimitive from '@radix-ui/react-dialog';
import { cva, type VariantProps } from 'class-variance-authority';
import { X } from 'lucide-react';
import { forwardRef, ElementRef, ComponentPropsWithoutRef, HTMLAttributes } from 'react';

import { cn } from '~/lib/utils';

const Sheet = SheetPrimitive.Root;
const SheetTrigger = SheetPrimitive.Trigger;
const SheetClose = SheetPrimitive.Close;
const SheetPortal = SheetPrimitive.Portal;

const SheetOverlay = forwardRef<
  ElementRef<typeof SheetPrimitive.Overlay>,
  ComponentPropsWithoutRef<typeof SheetPrimitive.Overlay>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Overlay
    className={cn(
      `
        fixed inset-0 z-40 bg-background/20
        data-[state=closed]:animate-out data-[state=closed]:fade-out-0
        data-[state=open]:animate-in data-[state=open]:fade-in-0
      `,
      className,
    )}
    {...props}
    ref={ref}
  />
));

SheetOverlay.displayName = SheetPrimitive.Overlay.displayName;

const sheetVariants = cva(
  `
    fixed z-40 gap-4 p-4 shadow-lg backdrop-blur-md transition ease-in-out
    data-[state=closed]:duration-300 data-[state=closed]:animate-out
    data-[state=open]:duration-500 data-[state=open]:animate-in
  `,
  {
    variants: {
      side: {
        top: `
          inset-x-0 top-0 border-b
          data-[state=closed]:slide-out-to-top
          data-[state=open]:slide-in-from-top
        `,
        bottom: `
          inset-x-0 bottom-0 border-t
          data-[state=closed]:slide-out-to-bottom
          data-[state=open]:slide-in-from-bottom
        `,
        left: `
          inset-y-0 left-0 h-full w-3/4 border-r
          data-[state=closed]:slide-out-to-left
          data-[state=open]:slide-in-from-left
        `,
        right: `
          inset-y-0 right-0 h-full w-3/4 border-l
          data-[state=closed]:slide-out-to-right
          data-[state=open]:slide-in-from-right
        `,
      },
    },
    defaultVariants: {
      side: 'right',
    },
  },
);

interface SheetContentProps
  extends ComponentPropsWithoutRef<typeof SheetPrimitive.Content>,
    VariantProps<typeof sheetVariants> {}

const SheetContent = forwardRef<ElementRef<typeof SheetPrimitive.Content>, SheetContentProps>(
  ({ side = 'right', className, children, ...props }, ref) => (
    <SheetPortal>
      <SheetOverlay />
      <SheetPrimitive.Content
        ref={ref}
        className={cn(sheetVariants({ side }), className)}
        {...props}
      >
        {children}
        <SheetPrimitive.Close
          className={`
            absolute right-4 top-4 rounded-sm opacity-70 ring-offset-background transition-opacity
            data-[state=open]:bg-secondary
            disabled:pointer-events-none
            focus:outline-none focus:ring-2 focus:ring-ring focus:ring-offset-2
            hover:opacity-100
          `}
        >
          <X className="size-6" />
          <span className="sr-only">Close</span>
        </SheetPrimitive.Close>
      </SheetPrimitive.Content>
    </SheetPortal>
  ),
);

SheetContent.displayName = SheetPrimitive.Content.displayName;

const SheetHeader = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div className={cn(`flex flex-col space-y-2 text-left`, className)} {...props} />
);

SheetHeader.displayName = 'SheetHeader';

const SheetFooter = ({ className, ...props }: HTMLAttributes<HTMLDivElement>) => (
  <div
    className={cn(
      `
        flex flex-col-reverse
        sm:flex-row sm:justify-end sm:space-x-2
      `,
      className,
    )}
    {...props}
  />
);

SheetFooter.displayName = 'SheetFooter';

const SheetTitle = forwardRef<
  ElementRef<typeof SheetPrimitive.Title>,
  ComponentPropsWithoutRef<typeof SheetPrimitive.Title>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Title
    ref={ref}
    className={cn('text-2xl font-bold text-foreground', className)}
    {...props}
  />
));

SheetTitle.displayName = SheetPrimitive.Title.displayName;

const SheetDescription = forwardRef<
  ElementRef<typeof SheetPrimitive.Description>,
  ComponentPropsWithoutRef<typeof SheetPrimitive.Description>
>(({ className, ...props }, ref) => (
  <SheetPrimitive.Description
    ref={ref}
    className={cn('text-sm text-muted-foreground', className)}
    {...props}
  />
));

SheetDescription.displayName = SheetPrimitive.Description.displayName;

export {
  Sheet,
  SheetPortal,
  SheetOverlay,
  SheetTrigger,
  SheetClose,
  SheetContent,
  SheetHeader,
  SheetFooter,
  SheetTitle,
  SheetDescription,
};
